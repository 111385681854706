<template>
  <div id="live-simulator">
    <svg xmlns="http://www.w3.org/2000/svg" id="svg965" viewBox="0 0 2100.8 1822.4" width="100%" y="0px" x="0px">
      <g id="g1070">
        <rect x="214.80005" y="124.8" width="261.89999" height="109" id="rect898" fill="#9c27b0" />
        <rect x="496.70001" y="124.8" width="261.89999" height="109" id="rect900" fill="#e6e6e6" />
        <rect x="778.5" y="124.8" width="261.89999" height="109" id="rect902" fill="#e6e6e6" />
        <rect x="1060.4" y="124.8" width="261.89999" height="109" id="rect904" fill="#e6e6e6" />
        <rect x="1342.3" y="124.8" width="261.89999" height="109" id="rect906" fill="#e6e6e6" />
        <rect x="1624.1" y="124.8" width="261.89999" height="109" id="rect908" fill="#e6e6e6" />
      </g>
      <g id="g1062">
        <rect x="214.80005" y="1059" width="261.89999" height="109" id="rect912" fill="#9c27b0" />
        <rect x="496.70001" y="1059" width="261.89999" height="109" id="rect914" fill="#9c27b0" />
        <rect x="778.5" y="1059" width="261.89999" height="109" id="rect916" fill="#9c27b0" />
        <rect x="1060.4" y="1059" width="261.89999" height="109" id="rect918" fill="#9c27b0" />
      </g>
      <rect x="214.80005" y="357" width="1107.4" height="580" id="rect922" fill="#e6e6e6" />
      <g id="g1056">
        <rect x="214.80005" y="1317.4" width="1107.4" height="20.6" id="rect924" fill="#e6e6e6" />
        <rect x="214.80005" y="1387.7" width="1107.4" height="20.6" id="rect926" fill="#e6e6e6" />
        <rect x="214.80005" y="1247.1" width="1107.4" height="20.6" id="rect928" fill="#e6e6e6" />
        <rect x="214.80005" y="1458.1001" width="1107.4" height="20.6" id="rect930" fill="#e6e6e6" />
        <rect x="214.80005" y="1528.3999" width="1107.4" height="20.6" id="rect932" fill="#e6e6e6" />
        <rect x="214.80005" y="1598.7" width="1107.4" height="20.6" id="rect934" fill="#e6e6e6" />
        <rect x="214.80005" y="1669" width="1107.4" height="20.6" id="rect936" fill="#e6e6e6" />
      </g>
      <g id="g1047">
        <rect x="1445.4" y="357" width="440.60001" height="228.39999" id="rect940" fill="#e6e6e6" />
        <rect x="1445.4" y="615.40002" width="440.60001" height="20.6" id="rect942" fill="#e6e6e6" />
      </g>
      <g id="g1039">
        <rect x="1445.4" y="1059" width="440.60001" height="228.39999" id="rect946" fill="#e6e6e6" />
        <rect x="1445.4" y="1317.4" width="440.60001" height="20.6" id="rect948" fill="#e6e6e6" />
      </g>
      <g id="g1035">
        <rect x="1445.4" y="1410.6" width="440.60001" height="228.39999" id="rect952" fill="#e6e6e6" />
        <rect x="1445.4" y="1669" width="440.60001" height="20.6" id="rect954" fill="#e6e6e6" />
      </g>
      <g id="g1043">
        <rect x="1445.4" y="708.59998" width="440.60001" height="228.39999" id="rect958" fill="#e6e6e6" />
        <rect x="1445.4" y="967" width="440.60001" height="20.6" id="rect960" fill="#e6e6e6" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LiveSimulator',
  mounted() {
    setTimeout(() => {
      this.loadWidget();
    }, 500);
  },
  methods: {
    async loadWidget() {
      const w = window;

      if (w.YupChat || document.getElementById('live-script-load')) return;

      const accountId = w.location.hash.substr(1);

      w.widgetLoaded = true;

      const inject = document.createElement('script');
      inject.id = 'live-script-load';
      inject.defer = true;
      inject.src = `${this.$live.widget}/yupchat.js`;
      inject.onload = () => {
        const widget = w.YupChat();
        widget.config({
          align: 'right',
          /*
          color: '#0E1421',
          pushColor: '#00cc00',
          icon: 'ICON1',
          */
          title: 'Yup Chat',
          intro: 'Olá tudo bem? Para poder ajudar, qual seu nome completo?',
        });
        widget.open(accountId);
      };
      document.body.appendChild(inject);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep button {
  outline: none;
}

#live-simulator {
  min-height: 100vh;
  width: 100%;
  background: #fff;
  text-align: center;

  svg {
    max-width: 1200px;
    margin: 0 auto;
    opacity: .5;
    pointer-events: none;
  }
}
</style>
